.simple-marquee-container *{
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	-o-box-sizing:border-box;
	box-sizing:border-box;
	font-family:Arial, "Helvetica Neue", Helvetica, sans-serif;
}
.simple-marquee-container {
	width: 100%;
	background: grey;
	float: left;
	display: inline-block;
	overflow: hidden;
	box-sizing: border-box;
	height: 45px;
	position: relative;
	cursor: pointer;
}

.simple-marquee-container .marquee-sibling {
	padding: 0;
	background: rgb(61, 61, 61);
	width: 20%;
	height: 45px;
	line-height: 42px;
	font-size: 12px;
	font-weight: normal;
	color: #ffffff;
	text-align: center;
	float: left;
	left: 0;
	z-index: 2000;
}

.simple-marquee-container .marquee, .simple-marquee-container *[class^="marquee"] {
	display: inline-block;
	white-space: nowrap;
	position:absolute;
}

.simple-marquee-container .marquee{
  margin-left: 25%;
}

.simple-marquee-container .marquee-content-items{
  display: inline-block;
  padding: 5px;
  margin: 0;
  height: 45px;
  position: relative;
}

.simple-marquee-container .marquee-content-items li{
  display: inline-block;
  line-height: 35px;
  color: #fff;
}

.simple-marquee-container .marquee-content-items li:after{
	content: "|";
	margin: 0 1em;
}
